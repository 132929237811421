export default {
    created() {
        this.getTableColumns()
    },
    methods: {
        async getTableColumns() {
            try {
                await this.$store.dispatch('workgroups/getTableColumns', {
                    listProject: this.listProject
                })
            } catch(e) {
                console.log(e)
            }
        }
    }
}